table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr {
  border-bottom: 1px solid var(--grey-50, rgba(6, 0, 27, 0.05));
  background: var(--solid-white, #fff);
}

.table-header {
  color: var(--primary-500, #120051);
  font-family: "Roboto" sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  text-transform: uppercase;
  /* background-color: #06001b0d; */
}

tr:nth-child(even) {
  /* background-color: #dddddd; */
}
