.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #120051;
  border-color: #120051;
  color: rgba(18, 0, 81, 1);
}

.ant-menu-title-content {
  padding-left: 10px;
}

.ant-menu-item {
  margin-bottom: 24px !important;
}

.ql-toolbar.ql-snow {
  border-color: transparent;
  border-radius: 12px;
  background: rgba(6, 0, 27, 0.02);
}

.ql-container.ql-snow {
  border-color: transparent;
  border-radius: 12px;
  background: rgba(6, 0, 27, 0.02);
  margin-top: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-menu-item-selected {
  background-color: rgba(6, 0, 27, 0.05) !important;
  color: rgba(18, 0, 81, 1) !important;
  font-weight: 700;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
